<template>
  <div
    ref="loader"
    class="preloader"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      style="display: none;"
    >
      <symbol id="wave">
        <path
          d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,
518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"
        />
        <path
          d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,
6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"
        />
        <path
          d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,
6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"
        />
        <path
          d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,
6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"
        />
      </symbol>
    </svg>
    <div class="box">
      <div
        id="water"
        class="water"
      >
        <svg
          viewBox="0 0 560 20"
          class="water_wave water_wave_back"
        >
          <use xlink:href="#wave" />
        </svg>
        <svg
          viewBox="0 0 560 20"
          class="water_wave water_wave_front"
        >
          <use xlink:href="#wave" />
        </svg>
      </div>
    </div>
    <div class="percent">
      <div
        id="count"
        class="percentNum"
      >
        0
      </div>
      <div class="percentB">
        %
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThePreloader',
  mounted() {
    this.initPreloader();
  },
  methods: {
    initPreloader() {
      const { body } = document;
      const preloader = document.querySelector('.preloader');
      const cnt = document.getElementById('count');
      const water = document.getElementById('water');
      let percent = Number(cnt.innerText);
      let interval = '';
      interval = setInterval(() => {
        percent += 1;
        cnt.innerHTML = percent;
        water.style.transform = `${'translate(0,'}${100 - percent}%)`;
        if (percent === 100) {
          clearInterval(interval);
          preloader.style.opacity = 0;
          body.style.overflow = 'auto';
          body.style.paddingRight = 0;
          this.$emit('preloader');
        }
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99;
  transition: 1s;
  pointer-events: none;
  //opacity: 0.4;
}

.box {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  border-radius: var(--border-radius);
  overflow: hidden;

  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
    background: var(--color-primary);
    transition: all 0.3s;

    &_wave {
      width: 200%;
      position: absolute;
      bottom: 100%;

      &_back {
        right: 0;
        fill: #fff;
        animation: wave-back 1.4s infinite linear;
      }

      &_front {
        left: 0;
        fill: var(--color-primary);
        margin-bottom: -1px;
        animation: wave-front 0.7s infinite linear;
      }
    }
  }
}

.percent {
  position: absolute;
  left: 0;
  top: 78px;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}
</style>
