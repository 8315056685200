<template>
  <div>
    <header class="header">
      <div class="container-fluid">
        <a
          class="logo cursor-hover"
          @click="scrollToElement('.header')"
        >
          <img
            src="@/assets/images/logo.jpg"
            alt="logo"
          >
          <p>Digital-agency</p>
        </a>
        <div class="main-menu">
          <a
            class="cursor-hover"
            @click="scrollToElement('.section-price')"
          >Прайс</a>
          <a
            class="cursor-hover"
            @click="scrollToElement('.stages')"
          >Этапы работы</a>
          <a
            class="cursor-hover"
            @click="scrollToElement('.projects')"
          >Портфолио</a>
          <a
            class="cursor-hover"
            @click="scrollToElement('.contacts')"
          >Контакты</a>
        </div>
        <div
          class="burger"
          @click="toggleMenu()"
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </header>
    <div class="contact-btn cursor-hover">
      <span class="no-blend">digital</span>
      <span class="blend">@digital-agency.com</span>
    </div>
    <div
      class="back-to-top-btn cursor-hover"
      @click="scrollToElement('.header')"
    >
      <span /> Вернуться наверх
    </div>
  </div>
</template>

<script>
import gsap from 'gsap/dist/gsap';
import scrollTo from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(scrollTo);

export default {
  name: 'TheHeader',
  methods: {
    scrollToElement(selector) {
      const element = document.querySelector(selector);
      let offset = '';
      if (selector === '.section-price') {
        const firstScreen = document.querySelector('.main-screen');
        const firstScreenHeight = firstScreen.offsetHeight;
        offset = firstScreenHeight * 1.5;
      } else {
        offset = element.offsetTop;
      }
      gsap.to(window, { duration: 0.8, scrollTo: offset });
    },
    toggleMenu() {
      const { body } = document;
      body.classList.toggle('menu-active');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba($color: #000, $alpha: 0.4);
  z-index: 10;
  backdrop-filter: blur(15px);
  // for animation
  opacity: 0;

  .container-fluid {
    display: flex;
  }

  .logo {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    text-decoration: none;

    img {
      width: 100%;
      max-width: 90px;
    }

    p {
      white-space: nowrap;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      margin-left: 30px;
    }

    > * {
      pointer-events: none;
    }
  }
}

.main-menu {
  margin-left: auto;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  transition: 0.3s ease 0.3s;
  padding: 60px;
  pointer-events: none;

  a {
    display: block;
    position: relative;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    padding: 30px 0;
    font-size: 18px;
    opacity: 0;
    transform: translateX(-40px);

    &:nth-child(1) {
      transition: 0.2s ease;
    }

    &:nth-child(2) {
      transition: 0.2s ease 0.1s;
    }

    &:nth-child(3) {
      transition: 0.2s ease 0.2s;
    }

    &:nth-child(4) {
      transition: 0.2s ease 0.3s;
    }

    @include md-block() {
      display: inline-block;
      margin-left: 40px;
      color: #fff;
      font-size: 20px;
      opacity: 1;
      transform: none;
      pointer-events: all;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    background: var(--color-primary);
    border-radius: 100%;
    top: -105px;
    right: -105px;
    transition: 0.3s ease 0.3s;
  }

  @include md-block() {
    display: block;
    opacity: 1;
    position: relative;
    width: auto;
    height: auto;
    padding: 0;

    &::before {
      display: none;
    }
  }
}

.burger {
  position: relative;
  width: 40px;
  height: 30px;
  margin: 20px 0 15px auto;
  transition: transform 0.4s ease 0.2s;

  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    left: 0;
    background: var(--color-primary);
    transition: 0.4s ease 0.2s;

    &:nth-child(2) {
      top: 14px;
    }

    &:nth-child(3) {
      top: auto;
      bottom: 0;
    }
  }

  @include md-block() {
    display: none;
  }
}

.contact-btn {
  position: fixed;
  left: 15px;
  bottom: 32px;
  color: #fff;
  z-index: 2;
  transform: rotate(-180deg);
  writing-mode: tb-rl;
  mix-blend-mode: difference;
  font-size: 12px;
  text-transform: uppercase;
  // for animation
  opacity: 0;

  .blend {
    mix-blend-mode: difference;
    pointer-events: none;
  }

  .no-blend {
    color: var(--color-primary);
    pointer-events: none;
  }

  @include md-block() {
    left: 45px;
    font-size: 14px;
  }
}

.back-to-top-btn {
  position: fixed;
  bottom: 32px;
  right: 15px;
  color: #fff;
  z-index: 2;
  writing-mode: tb-rl;
  mix-blend-mode: difference;
  font-size: 12px;
  text-transform: uppercase;
  // for animation
  opacity: 0;

  span {
    position: absolute;
    background: url('~@/assets/images/arrow.svg') no-repeat center;
    width: 65px;
    height: 20px;
    display: inline-block;
    transform:
      rotate(
        270deg
      );
    background-size: contain;
    left: -27px;
    top: -52px;
  }

  @include md-block() {
    right: 45px;
    font-size: 14px;
  }
}

.menu-active {
  .main-menu {
    transition: 0.3s ease;
    opacity: 1;
    pointer-events: all;

    &::before {
      width: 100vw;
      height: 100vh;
      top: 0;
      right: 0;
      border-radius: 0;
      transition: 0.3s ease;
    }

    a {
      opacity: 1;
      transform: translateX(0);

      &:nth-child(1) {
        transition: 0.2s ease 0.3s;
      }

      &:nth-child(2) {
        transition: 0.2s ease 0.4s;
      }

      &:nth-child(3) {
        transition: 0.2s ease 0.5s;
      }

      &:nth-child(4) {
        transition: 0.2s ease 0.6s;
      }
    }
  }

  .burger {
    transform: rotate(270deg);
    transition: transform 0.4s ease;

    span {
      background: #000;
      transition: 0.4s ease;

      &:nth-child(1) {
        transform: rotate(44deg) translate(9px, 10px);
      }

      &:nth-child(2) {
        transform: translateX(-15px);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-44deg) translate(10px, -10px);
      }
    }
  }
}
</style>
