<template>
  <div class="price-section">
    <div class="container">
      <h2>Цены на наши услуги</h2>
      <div class="price-wrapper">
        <div class="price-grid">
          <div class="price-item">
            <div class="title">
              Одностраничный сайт
            </div>
            <div class="price">
              от 25 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
          <div class="price-item">
            <div class="title">
              Интернет-магазин
            </div>
            <div class="price">
              от 120 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
          <div class="price-item">
            <div class="title">
              Крупный ресурс
            </div>
            <div class="price">
              от 300 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
          <div class="price-item">
            <div class="title">
              Приложение
            </div>
            <div class="price">
              от 200 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
          <div class="price-item">
            <div class="title">
              SEO-продвижение
            </div>
            <div class="price">
              от 20 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
          <div class="price-item">
            <div class="title">
              Контекстная реклама
            </div>
            <div class="price">
              от 20 000 ₽
            </div>
            <button class="button button-primary cursor-hover">
              Заказать
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap/dist/gsap';
import scrollTrigger from 'gsap/dist/ScrollTrigger';
// eslint-disable-next-line no-unused-vars
import EasePack from 'gsap/dist/EasePack';

gsap.registerPlugin(scrollTrigger);

export default {
  name: 'ThePrice',
  data() {
    return {
      tl1: '',
      offset: '',
    };
  },
  mounted() {
    this.initPriceAnimation();
  },
  destroy() {
    window.removeEventListener('resize', this.resizePriceScroll);
  },
  methods: {
    getOffset() {
      const grid = document.querySelector('.price-grid');
      const items = document.querySelectorAll('.price-item');
      const item = document.querySelector('.price-item');
      const itemsLength = items.length;
      const itemWidth = item.offsetWidth;
      const gridWidth = grid.offsetWidth;
      const offset = (itemWidth * itemsLength) + (32 * (itemsLength - 1)) - gridWidth;

      return -offset;
    },
    initPriceAnimation() {
      const wrapper = document.querySelector('.price-section');
      this.offset = this.getOffset();

      this.tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'top top',
          end: 'bottom+=600 100',
          markers: false,
          scrub: 0.5,
          toggleAction: 'play none reverse resume',
          pin: true,
          invalidateOnRefresh: true,
        },
      });
      this.tl1
        .to('.price-item', {
          x: () => {
            const grid = document.querySelector('.price-grid');
            const items = document.querySelectorAll('.price-item');
            const item = document.querySelector('.price-item');
            const itemsLength = items.length;
            const itemWidth = item.offsetWidth;
            const gridWidth = grid.offsetWidth;
            const offset = (itemWidth * itemsLength) + (32 * (itemsLength - 1)) - gridWidth;
            return -offset;
          },
          stagger: 0.3,
          duration: 13,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .price-section {
    padding: var(--padding-section);
    background: #d6d6d6;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .price-grid {
      // @include row-flex();

      display: grid;
      grid-template-columns: repeat(6, 100%);
      grid-gap: 32px;

      @include md-block() {
        grid-template-columns: repeat(6, 500px);
      }
    }

    .price-item {
      // @include col();
      // @include size(4);
      display: flex;
      flex-direction: column;
      color: #000;
      padding: 40px;
      margin-bottom: 32px;
      border-radius: var(--border-radius);
      height: 300px;
      background: #fff !important;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

      &:nth-child(1) {
        background: linear-gradient(-30deg, var(--color-primary), var(--color-primary-300));
      }

      &:nth-child(2) {
        background: linear-gradient(-30deg, var(--color-secondary), var(--color-secondary-300));
      }

      &:nth-child(3) {
        background: linear-gradient(-30deg, var(--color-tertiary), var(--color-tertiary-300));
      }

      &:nth-child(4) {
        background: linear-gradient(-30deg, var(--color-quaternary), var(--color-quaternary-300));
      }

      &:nth-child(5) {
        background: linear-gradient(-30deg, var(--color-quinary), var(--color-quinary-300));
      }

      &:nth-child(6) {
        background: linear-gradient(-30deg, var(--color-senary), var(--color-senary));
      }

      .title {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 20px;
      }

      .price {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .button {
        margin-top: auto;
      }
    }

    h2 {
      margin-bottom: 60px;
    }
  }
</style>
