<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss">
  @import '../node_modules/reset-css/reset.css';
  @import 'assets/fonts/icons/icons.css';
  @import 'theme/scss/global.scss';
</style>
