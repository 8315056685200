<template>
  <div>
    <div class="main-screen">
      <canvas id="canvas-bg" />
      <div class="container">
        <div class="main-inner">
          <h1>Услуги для малого<br>и среднего бизнеса</h1>
          <h3>SEO, CPC, создание сайтов.</h3>
        </div>
        <div class="second-inner">
          Гибкие решения для ваших задач и немного магии
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import gsap from 'gsap/dist/gsap';
import scrollTrigger from 'gsap/dist/ScrollTrigger';

import PerlinNoise3d from 'perlin-noise-3d';

gsap.registerPlugin(scrollTrigger);

export default {
  name: 'TheMain',
  mounted() {
    this.initMainAnimation();
  },
  methods: {
    initMainAnimation() {
      // Sizes
      const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      // Scene
      const scene = new THREE.Scene();

      // Camera
      const camera = new THREE.PerspectiveCamera(
        75,
        sizes.width / sizes.height,
        1,
        1000,
      );
      camera.position.x = 0;
      camera.position.y = 0;
      camera.position.z = 4.5;

      // Renderer
      const canvas = document.querySelector('#canvas-bg');
      const renderer = new THREE.WebGLRenderer({
        canvas,
        alpha: true,
        antialias: true,
      });

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(sizes.width, sizes.height);
      renderer.render(scene, camera);

      // Objects

      const path = '/img/reflex/';
      const format = '.jpg';
      const urls = [
        `${path}px${format}`, `${path}nx${format}`,
        `${path}py${format}`, `${path}ny${format}`,
        `${path}pz${format}`, `${path}nz${format}`,
      ];

      const reflectionCube = new THREE.CubeTextureLoader().load(urls);
      const colorPrimary = new THREE.Color(0xc7ff00);
      const colorSecondary = new THREE.Color(0x000000);
      const geometry = new THREE.SphereGeometry(10, 40, 40);
      const reflexMaterial = new THREE.MeshStandardMaterial({
        color: colorPrimary,
        envMap: reflectionCube,
        envMapIntensity: 0.8,
        wireframe: false,
        roughness: 0.05,
        metalness: 0.3,
      });

      reflexMaterial.needsUpdate = true;

      const sphere = new THREE.Mesh(geometry, reflexMaterial);

      sphere.rotation.y = -10;
      sphere.position.x = 1;

      scene.add(sphere);

      // Lights
      const pointLight = new THREE.PointLight(0xffffff, 0.7);
      const pointLight2 = new THREE.PointLight(0x4100bf, 0.7);
      pointLight.position.set(-5, 0, 5);
      pointLight2.position.set(5, 0, 4);

      scene.add(pointLight);

      // Animation

      const screen = document.querySelector('.main-screen');
      const inner = document.querySelector('.main-inner');
      const secondInner = document.querySelector('.second-inner');
      const noise = new PerlinNoise3d();
      const scrollStart = 'top top';
      const scrollEnd = 'bottom-=300 center-=300';
      const scrollActions = 'restart none none none';
      const scrollMarkers = false;
      const scrollScrub = 1;
      const pin = true;

      const settings = {
        time: 0,
        spikes: 0.3,
        size: 1,
        amp: 1.2,
      };

      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: screen,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
          pin,
        },
      });
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: secondInner,
          start: 'bottom+=200 top',
          end: 'bottom+=500 top',
          markers: false,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl1
        .to(inner, { y: -600 }, 'point1')
        .to(secondInner, { x: 0 }, 'point1')
        .to(pointLight.position, { x: 10 }, 'point1')
        .to(pointLight, { intensity: 0.2 }, 'point1')
        .to(sphere.position, { x: 0 }, 'point1')
        .to(sphere.material.color, { r: colorSecondary.r, g: colorSecondary.g, b: colorSecondary.b }, 'point1')
        .to(pointLight.color, { r: colorPrimary.r, g: colorPrimary.g, b: colorPrimary.b }, 'point1')
        .to(settings, { spikes: '+=0.8' }, 'point1')
        .to(settings, { time: '+=1' }, 'point1')
        .to(settings, { size: '-=0.8' }, 'point1')
        .to(settings, { amp: '+=1.5' }, 'point1');

      tl2
        .to(secondInner, { x: -500, opacity: 0 }, 'point2')
        .to(sphere.position, { y: -0.6 }, 'point2')
        .to(sphere.position, { z: 1.6 }, 'point2');

      const update = () => {
        sphere.traverse((child) => {
          if (child.isMesh) {
            const positionAttribute = sphere.geometry.getAttribute('position');
            const vector = new THREE.Vector3();

            settings.time = performance.now() * 0.0003;

            for (let i = 0, l = positionAttribute.count; i < l; i += 1) {
              vector.fromBufferAttribute(positionAttribute, i);

              vector
                .normalize()
                .multiplyScalar(
                  settings.size + settings.amp * noise.get(
                    vector.x * settings.spikes + settings.time,
                    vector.y * settings.spikes, vector.z * settings.spikes,
                  ),
                );

              positionAttribute.setXYZ(i, vector.x, vector.y, vector.z);
            }

            sphere.geometry.attributes.position.needsUpdate = true;

            child.geometry.computeVertexNormals();
            child.geometry.computeBoundingSphere();
          }
        });
        geometry.normalizeNormals();
      };

      function animate() {
        update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      }

      animate();

      // Update scene on resize
      window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        // Update camera
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();

        // Update renderer
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      });

      // Easter egg (press Ctrl + B)

      // function KeyPress(event) {
      //   const evtobj = event;
      //   if (evtobj.keyCode === 66 && evtobj.ctrlKey) {
      //     const urls2 = [
      //       `${path}px${format}`, `${path}nx${format}`,
      //       `${path}py${format}`, `${path}ny${format}`,
      //       `${path}pzz${format}`, `${path}nz${format}`,
      //     ];

      //     const reflectionCube2 = new THREE.CubeTextureLoader().load(urls2);
      //     const reflexMaterial2 = new THREE.MeshStandardMaterial({
      //       color: colorPrimary,
      //       envMap: reflectionCube2,
      //       envMapIntensity: 0.8,
      //       wireframe: false,
      //       roughness: 0.05,
      //       metalness: 0.3,
      //     });

      //     reflexMaterial.needsUpdate = true;

      //     const sphere2 = new THREE.Mesh(geometry, reflexMaterial2);
      //     scene.remove(sphere);

      //     sphere2.position.x = 1;
      //     scene.add(sphere2);
      //     gsap.to(inner, { x: -280, opacity: 0.2, duration: 1 });
      //     gsap.to(sphere2.position, {
      //       x: 0, z: 1, duration: 1,
      //     });
      //     renderer.render(scene, camera);
      //   }
      // }

      // document.onkeydown = KeyPress;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-screen {
  height: 100vh;
  background: #000;
  overflow: hidden;

  .second-inner {
    position: absolute;
    font-size: var(--font-size-h2);
    font-weight: 200;
    color: #fff;
    transform: translateX(2000px);
    max-width: 100%;

    @include md-block() {
      max-width: initial;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000ad;
    z-index: -1;
    display: none;
  }

  #canvas-bg {
    position: fixed;
    z-index: -1;
    // for animation
    opacity: 0;
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  h1 {
    font-size: 45px;
    font-weight: 800;
    color: var(--color-white);

    @include md-block() {
      font-size: 70px;
    }
  }

  h3 {
    font-size: 20px;
    color: var(--color-white);
    margin-top: 20px;

    @include md-block() {
      font-size: 25px;
    }
  }
}
</style>
