<template>
  <div class="contacts">
    <div class="container">
      <h2>Контакты</h2>
      <div class="contacts-grid">
        <div class="col">
          <div class="phone">
            8 (495) 777-77-77
          </div>
          <div class="addr">
            БЦ "Водный" - Москва, Головинское ш., 5
          </div>
        </div>
        <div class="col">
          <div class="map">
            <div style="position: relative; overflow: hidden;">
              <a
                href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
                style="color: #eee; font-size: 12px; position: absolute; top: 0;"
              >
                Москва
              </a>
              <a
                href="https://yandex.ru/maps/213/moscow/house/golovinskoye_shosse_5k1/Z04Ycg5iSUcDQFtvfXR1cX5nYQ==/?ll=37.492024%2C55.840245&utm_medium=mapframe&utm_source=maps&z=17.1"
                style="color: #eee; font-size: 12px; position: absolute; top: 14px;"
              >
                Головинское шоссе, 5к1 — Яндекс.Карты
              </a>
              <iframe
                src="https://yandex.ru/map-widget/v1/-/CCUa6NXC3C"
                width="100%"
                height="400"
                frameborder="1"
                allowfullscreen="true"
                style="position: relative;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheContacts',
};
</script>

<style lang="scss" scoped>
  .contacts {
    padding: var(--padding-section);
    background: #fff;

    &-grid {
      @include row-flex();
    }

    .col {
      @include col();
      @include size(12);
      @include size-md(6);

      font-size: 20px;
    }

    .addr {
      margin-top: 40px;
    }

    .map {
      border-radius: var(--border-radius);
      overflow: hidden;
      margin-top: 40px;

      @include md-block() {
        margin-top: 0;
      }
    }
  }
</style>
