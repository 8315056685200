<template>
  <div>
    <ThePreloader @preloader="initIntroAnimation()" />
    <TheHeader />
    <TheMain />
    <ThePrice />
    <TheStages />
    <TheProjects />
    <TheContacts />
    <svg
      style="visibility: hidden; position: absolute;"
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="goo"><feGaussianBlur
                           in="SourceGraphic"
                           stdDeviation="10"
                           result="blur"
                         />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite
            in="SourceGraphic"
            in2="goo"
            operator="atop"
          />
        </filter>
      </defs>
    </svg>
    <div class="cursor-follow" />
  </div>
</template>

<script>
import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import ThePreloader from '@/components/app/ThePreloader.vue';
import TheHeader from '@/components/app/TheHeader.vue';
import TheMain from '@/components/app/TheMain.vue';
import ThePrice from '@/components/app/ThePrice.vue';
import TheStages from '@/components/app/TheStages.vue';
import TheProjects from '@/components/app/TheProjects.vue';
import TheContacts from '@/components/app/TheContacts.vue';

gsap.registerPlugin(ScrollTrigger);
// import { Power3, Bounce } from 'gsap/dist/EasePack';

export default {
  components: {
    ThePreloader,
    TheHeader,
    TheMain,
    ThePrice,
    TheStages,
    TheProjects,
    TheContacts,
  },
  layout: 'default',
  mounted() {
    // this.initIntroAnimation();
    // this.disableEventsOnScroll();
    this.initCursorAnimation();
  },
  methods: {
    initIntroAnimation() {
      // const screen = document.querySelector('.main-screen');
      const header = document.querySelector('header');
      const contactBtn = document.querySelector('.contact-btn');
      const backtotopBtn = document.querySelector('.back-to-top-btn');
      const canvas = document.getElementById('canvas-bg');
      const text = document.querySelector('.main-inner');

      gsap.fromTo(header, { y: -100 }, {
        y: 0, opacity: 1, duration: 1.5, ease: 'power1',
      });
      gsap.fromTo(contactBtn, { x: -100 }, {
        x: 0, opacity: 1, duration: 1.5, ease: 'power1',
      });
      gsap.fromTo(backtotopBtn, { x: 100 }, {
        x: 0, opacity: 1, duration: 1.5, ease: 'power1',
      });
      gsap.fromTo(canvas, { scale: 0.5 }, {
        scale: 1.0, opacity: 1, duration: 1.8, ease: 'power2',
      });
      gsap.fromTo(text, { scale: 0.9, opacity: 0 }, {
        scale: 1.0, opacity: 1, duration: 2, ease: 'power2', delay: 0.0,
      });
    },
    disableEventsOnScroll() {
      const { body } = document;
      let timer;

      window.addEventListener('scroll', () => {
        clearTimeout(timer);
        if (!body.classList.contains('disable-hover')) {
          body.classList.add('disable-hover');
        }

        timer = setTimeout(() => {
          body.classList.remove('disable-hover');
        }, 200);
      }, false);
    },
    initSmoothScroll() {
      // const scrollWrapper = document.querySelector('.smooth-scroll');
    },
    initCursorAnimation() {
      const followCursor = document.querySelector('.cursor-follow');
      // const elements = document.querySelectorAll('a, .cursor-hover, .project-item');

      window.addEventListener('mousemove', (e) => {
        gsap.to(followCursor, {
          x: e.clientX,
          y: e.clientY,
          duration: 1.7,
          ease: 'elastic(1, 0.2)',
        });
        if (e.target.classList.contains('cursor-hover')) {
          gsap.to(followCursor, {
            width: 70,
            height: 70,
            marginLeft: -30,
            marginTop: -30,
            backdropFilter: 'invert(1)',
            duration: 0.3,
          });
        } else {
          gsap.to(followCursor, {
            width: 30,
            height: 30,
            marginLeft: -10,
            marginTop: -10,
            backdropFilter: 'invert(0)',
            duration: 0.2,
          });
        }
      });

      // gsap.to(followCursor, {
      //   width: 10,
      //   height: 10,
      //   marginLeft: 0,
      //   marginTop: 0,
      //   duration: 0.2,
      // });
      const tl = gsap.timeline({ reversed: true });
      tl.to(followCursor, {
        width: 10,
        height: 10,
        marginLeft: 0,
        marginTop: 0,
        duration: 0.2,
      });

      window.addEventListener('mousedown', (e) => {
        // e.stopPropagation();
        tl.play();
      });
      window.addEventListener('mouseup', () => {
        // console.log(e, 'mouseup');
        // e.stopPropagation();
        // gsap.to(followCursor, {
        //   width: 30,
        //   height: 30,
        //   marginLeft: -10,
        //   marginTop: -10,
        //   duration: 1.5,
        //   ease: 'elastic(1, 0.2)',
        // });
        tl.reverse();
      });
    },
  },
};
</script>

<style lang="scss">
  body {
    overflow: hidden;

    &.menu-active {
      overflow: hidden !important;
    }

    cursor: url('~@/assets/images/cursor.svg'), auto !important;
  }

  a:hover,
  button:hover {
    cursor: url('~@/assets/images/cursor.svg'), auto !important;
  }

  .container {
    @include container;

    width: 100%;
  }

  .container-fluid {
    @include container-fluid();
  }

  .button {
    font-size: 16px;
    padding: 15px 30px;
    border-radius: var(--border-radius);
  }

  .disable-hover {
    pointer-events: none;
  }

  .cursor-follow {
    position: fixed;
    width: 30px;
    height: 30px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    left: 0;
    top: 0;
    margin-left: -10px;
    margin-top: -10px;
    z-index: 10;
    pointer-events: none;
    box-shadow: 0 0 0 1px #00000024;
  }
</style>
