<template>
  <div class="projects">
    <div class="container">
      <h2>
        Наши кейсы
      </h2>
    </div>
    <div class="projects-wrapper">
      <div class="projects-grid grid-1">
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/vip.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/ice-potolok.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
      </div>
      <div class="projects-grid grid-2">
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/vip.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/ice-potolok.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
      </div>
      <div class="projects-grid grid-1">
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/vip.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/ice-potolok.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
      </div>
      <div class="projects-grid grid-2">
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/vip.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/ice-potolok.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/iceberg.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
        <div class="projects-item">
          <div class="image">
            <img
              src="@/assets/images/projects/handmaster.png"
              alt="project-name"
            >
          </div>
          <div class="title">
            Лендинг ice-potolok.ru
          </div>
          <div class="desc">
            Создание ультра-современного лендинга для компании по монтажу натяжных потолков
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap/dist/gsap';
import scrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(scrollTrigger);

export default {
  name: 'TheProjects',
  mounted() {
    this.initProjectsAnimation();
  },
  methods: {
    initProjectsAnimation() {
      const wrapper = document.querySelector('.projects');

      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'top bottom-=300',
          end: 'bottom 100',
          markers: false,
          scrub: 0.5,
          toggleAction: 'play none reverse resume',
          pin: false,
        },
      });

      tl1
        .to('.grid-1 .projects-item', { x: -100 })
        .to('.grid-2 .projects-item', { x: 100 }, '-=');
    },
  },
};
</script>

<style lang="scss" scoped>
.projects {
  position: relative;
  height: 100vh;
  background: #000;
  padding: var(--padding-section);
  overflow: hidden;

  &-wrapper {
    position: relative;
    overflow: hidden;
  }

  &-grid {
    display: flex;
    grid-gap: 15px;
    transform: rotate(15deg) translateX(-200px) translateY(-125px);
    margin-bottom: 25px;

    @include md-block() {
      transform: rotate(15deg) translateX(-200px) translateY(-200px);
    }
  }

  &-item {
    min-width: 350px;
    //cursor: pointer;
    overflow: hidden;
    border-radius: var(--border-radius);

    .image {
      height: 100%;
      z-index: 2;
      transition: transform 0.4s ease;
      will-change: transform;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
      display: none;
    }

    .desc {
      margin-top: 10px;
      display: none;
    }

    &:hover {
      //box-shadow: 0 0 10px 10px var(--color-primary);
      .image {
        transform: scale(1.06);
        z-index: 4;
      }
    }

    @include md-block() {
      min-width: 500px;
    }
  }

  h2 {
    margin-bottom: 60px;
    color: #fff;
  }
}
</style>
