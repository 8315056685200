<template>
  <div class="stages">
    <div class="container">
      <h2 data-symbol="Э">
        Этапы работы
      </h2>
      <div class="stages-grid">
        <div class="row">
          <div class="stages-item">
            <div class="count">
              1
            </div>
            <div class="title">
              Анализ тематики
            </div>
            <div class="desc">
              <p>
                Мы погружаемся в тематику клиента, начиная подробностями услуги и номенклатурой,
                заканчивая изучением конкурентов в органической и платной выдаче поисковых систем,
                топах агрегаторов и приложений.
              </p>

              <p>
                В итоге исследования мы способны ответить что сработает в той или иной тематике,
                какой объем работ и сколько ресурсов потребуется.
              </p>
            </div>
            <div class="time">
              <span class="bold">Время:</span> 2-3 недели
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
          <div class="arrow" />
        </div>
        <div class="row">
          <div class="arrow" />
          <div class="stages-item">
            <div class="count">
              2
            </div>
            <div class="title">
              Проектирование
            </div>
            <div class="desc">
              <p>
                Мы готовим подробный проект будущего сайта опираясь на результаты исследований.
              </p>

              <p>
                Проект детализируется вплоть до каждого url-адреса будущего сайта.
                Учитываются все необходимые секции, блоки и элементы.
              </p>
            </div>
            <div class="time">
              <span class="bold">Время:</span> 2 месяца
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="stages-item">
            <div class="count">
              3
            </div>
            <div class="title">
              Дизайн
            </div>
            <div class="desc">
              <p>
                Визуальные решения проектируются исходя из UX-требований тематики.
              </p>

              <p>
                Так же учитываем статистические данные, к примеру знаем самые
                популярные разрешения экранов смартфонов и персональных компьютеров.
              </p>
            </div>
            <div class="time">
              <span class="bold">Время:</span> 1-2 месяца
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
          <div class="arrow" />
        </div>
        <div class="row">
          <div class="arrow" />
          <div class="stages-item">
            <div class="count">
              4
            </div>
            <div class="title">
              Кодинг
            </div>
            <div class="desc">
              <p>
                Мы прекрасно разбираемся в современных фреймворках и технологиях
                (vue js, react, ssr, spa, БЭМ и т.д.).
              </p>

              <p>
                Благодаря тому что на этапе анализа мы выявляем весь необходимый
                функционал мы способны заранее спроектировать всю будущую архитектуру
                как визуальной части кода так и внутренней.
              </p>

              <p>
                На выходе мы можем выдать чистый, безопасный и самое главное
                быстрый сайт, сделанный на современных технологиях без костылей и палок.
              </p>
            </div>
            <div class="time">
              <span class="bold">Время:</span> 2-3 месяца
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="stages-item">
            <div class="count">
              5
            </div>
            <div class="title">
              Реклама
            </div>
            <div class="desc">
              <p>
                Мы способны сами профессионально рекламировать продукт.
                В особенности потому что хорошо разобрались в тематике на этапе
                анализа и проектирования.
              </p>

              <p>
                Начиная с Google ADS и Яндекс.Директ и заканчивая арбитражными
                системами, телеграмм-каналами, рекламными и партнерскими сетями.
              </p>

              <p>Готовим технические задания на акции и другие элементы перфоманс-маркетинга.</p>

              <p>
                Полностью закрываем рекламные потребности клиента. При этом готовим
                подробную отчетность.
              </p>
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
          <div class="arrow" />
        </div>
        <div class="row">
          <div class="arrow" />
          <div class="stages-item">
            <div class="count">
              6
            </div>
            <div class="title">
              Поисковая оптимизация
            </div>
            <div class="desc">
              <p>
                Благодаря том что сайт изначально спроектирован под требования поисковых
                систем намного проще привести пользователей из органической (бесплатной) выдачи.
              </p>

              <p>
                Кроме этого мы прекрасно разбираемся во всех современных SEO-технологиях:
              </p>
              <ul>
                <li>- LSI-оптимизация;</li>
                <li>- крауд-ссылки;</li>
                <li>- Контентная оптимизация;</li>
                <li>- on-page seo;</li>
                <li>- pdn-сети;</li>
                <li>- работа с поведенческими факторами;</li>
                <li>- тегирование;</li>
                <li>- размножение и генерация.</li>
              </ul>
            </div>
            <div class="experts">
              <span class="bold">Специалисты:</span>
              <p>
                - продакт-менеджер;
              </p><p>
                - SEO-аналитик;
              </p><p>
                - специалист по контекстной рекламе.
              </p>
            </div>
            <div class="docs">
              <span class="bold">Документация по итогам этапа:</span>
              <p>
                - анализ тематики;
              </p><p>
                - анализ конкуренции;
              </p><p>
                - стратегия (road-map) работы со сроками.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="stages-action">
        <h3>Хотите получите анализ вашего сайта бесплатно?</h3>
        <button class="button button-primary cursor-hover">
          Хочу
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap/dist/gsap';
import scrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(scrollTrigger);

export default {
  name: 'TheStages',
  mounted() {
    this.initStagesAnimation();
  },
  methods: {
    initStagesAnimation() {
      const screen = document.querySelector('.stages');
      const title = document.querySelector('.stages h2');
      const item1 = document.querySelector('.stages-grid .row:nth-child(1)');
      const item2 = document.querySelector('.stages-grid .row:nth-child(2)');
      const item3 = document.querySelector('.stages-grid .row:nth-child(3)');
      const item4 = document.querySelector('.stages-grid .row:nth-child(4)');
      const item5 = document.querySelector('.stages-grid .row:nth-child(5)');
      const item6 = document.querySelector('.stages-grid .row:nth-child(6)');

      const from = {
        y: 200,
        opacity: 0.4,
        scale: 0.8,
      };
      const to = {
        y: 0,
        opacity: 1,
        scale: 1.0,
      };
      const to2 = {
        opacity: 0.4,
        y: -100,
        scale: 0.9,
      };

      const scrollStart = 'top-=200 bottom-=100';
      const scrollEnd = 'bottom+=300 center';
      const scrollActions = 'restart none none none';
      const scrollMarkers = false;
      const scrollScrub = 0.5;

      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: item1,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl1.fromTo(item1, from, to).to(item1, to2);

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: item2,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl2.fromTo(item2, from, to).to(item2, to2);

      const tl3 = gsap.timeline({
        scrollTrigger: {
          trigger: item3,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl3.fromTo(item3, from, to).to(item3, to2);

      const tl4 = gsap.timeline({
        scrollTrigger: {
          trigger: item4,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl4.fromTo(item4, from, to).to(item4, to2);

      const tl5 = gsap.timeline({
        scrollTrigger: {
          trigger: item5,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl5.fromTo(item5, from, to).to(item5, to2);

      const tl6 = gsap.timeline({
        scrollTrigger: {
          trigger: item6,
          start: scrollStart,
          end: scrollEnd,
          markers: scrollMarkers,
          scrub: scrollScrub,
          toggleAction: scrollActions,
        },
      });

      tl6.fromTo(item6, from, to).to(item6, to2);

      const tl7 = gsap.timeline({
        scrollTrigger: {
          trigger: screen,
          start: scrollStart,
          end: 'bottom-=100 bottom-=100',
          markers: scrollMarkers,
          scrub: false,
          toggleAction: scrollActions,
        },
      });

      tl7.fromTo(title, { x: -300, opacity: 0 }, { x: 0, opacity: 1, duration: 1 });
    },
  },
};
</script>

<style lang="scss" scoped>
.stages {
  position: relative;
  padding: var(--padding-section);
  background: #000;
  overflow: hidden;

  &-grid {
    .row {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 100px;
      max-width: 800px;
      margin: 0 auto;

      @include md-block() {
        //grid-template-columns: repeat(2, 1fr);
      }

      .arrow {
        position: relative;
        display: none !important;
        opacity: 0;

        &::before {
          content: '';
          position: absolute;
          left: -58px;
          top: 150px;
          width: 120px;
          height: 120px;
          background: url('../../assets/images/arrow-down.svg') no-repeat center;
          background-size: contain;
        }

        @include md-block() {
          display: block;
        }
      }

      &:nth-child(2n) {
        position: relative;

        .arrow::before {
          transform: scale(-1, 1);
          left: auto;
          right: -58px;
        }
      }

      &:last-child {
        .arrow::before {
          display: none;
        }
      }
    }
  }

  &-item {
    position: relative;
    padding: 30px 40px;
    margin-bottom: 32px;
    background: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    z-index: 2;
    border-radius: var(--border-radius);
    border-bottom: 15px solid var(--color-primary);
    line-height: 20px;

    .count {
      font-size: 175px;
      font-weight: 800;
      position: absolute;
      right: -10px;
      bottom: 20px;
      z-index: -1;
      color: var(--color-primary) !important;
    }

    .title {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .desc,
    .time,
    .experts,
    .docs {
      margin-bottom: 20px;
    }

    .experts,
    .docs {
      display: none;
    }
  }

  &-action {
    padding: 140px 0 40px;
    text-align: center;
  }

  h2 {
    margin-bottom: 60px;
    color: #fff;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
  }

  p {
    margin-bottom: 20px;
  }

  .bold {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .button {
    margin-top: 40px;
    width: 100%;
    max-width: 250px;
  }
}
</style>
